export default {
  "en": {
    "sekai-ichi-smart-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Smart Art"])},
    "do-not-have-account ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t Have Account?"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGN IN"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "smart-art-welcomes-to-world-of-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Welcomes you to the world of art "])},
    "Sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGN UP"])},
    "agreement-for-international-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement for International delivery"])},
    "return-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Policy "])},
    "forgot-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "have-an-account ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an Account?"])},
    "artist ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARTIST"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUYER"])},
    "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORGANIZER"])},
    "photographer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographer "])},
    "designer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer "])},
    "architect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architect"])},
    "frame-vendor ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Vendor "])},
    "new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW USER"])},
    "member-of-jiipa-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMBER OF JIIPA SERVICES"])},
    "member-of-smart-recruit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMBER OF SMART RECRUIT"])},
    "member-of-sekai-ichi-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEMBER OF SEKAI-ICHI PRODUCTS"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFY"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTER"])},
    "industry ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry "])},
    "industry-subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry Subcategory"])},
    "designation ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation "])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department"])},
    "landline-telephone ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landline Telephone "])},
    "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "fax-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax Number"])},
    "company-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Website"])},
    "pin-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin Code"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "free-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE USER"])},
    "business-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUSINESS USER"])},
    "you-will-be-provided-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Be Provided With "])},
    "free-disk-space-to-upload-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk Space For Free To Upload Your Art Images."])},
    "optional-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIONAL SERVICES"])},
    "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
    "automatic-mobile-notifications-per-year\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Mobile Notifications Per Year:"])},
    "company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "select-below-option-for-additional-disk-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select The Option Below To Use Additional Disk Space"])},
    "file-server-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILE SERVER DETAILS"])},
    "rent-from-sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENT FROM SEKAI-ICHI"])},
    "own-file-server ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OWN FILE SERVER"])},
    "aws-connection-cost-per-year ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS Connection Cost per Year:"])},
    "server-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server URL"])},
    "target-bucket-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Bucket Name"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "secret-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secret Key"])},
    "test-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEST CONNECTION"])},
    "slack-notification-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLACK NOTIFICATION FEE"])},
    "aws-connection-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS CONNECTION FEES"])},
    "total-amount-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL AMOUNT TO PAY"])},
    "Japan-consumption-tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPAN CONSUMPTION TAX AMOUNT"])},
    "total-amount-excluding-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL AMOUNT EXCLUDING TAX"])},
    "way-to-smart-life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Way To Smart Life!"])},
    "we-provide-best-solutions-for- businesses-and-people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide best technological solutions for businesses and for the people. "])},
    "know-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know More"])},
    "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
    "amount-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount To Pay"])},
    "credit-debit-card ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDIT / DEBIT CARD"])},
    "name-on-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name on Card"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
    "expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry Date"])},
    "blank-fields ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blank Fields "])},
    "bank-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANK TRANSFER"])},
    "please-make-payment-within ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLEASE MAKE PAYMENT WITHIN "])},
    "to-below-bank-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO BELOW BANK ACCOUNT"])},
    "account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "bank-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
    "branch-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Name "])},
    "account-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Type"])},
    "account-number ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number "])},
    "swift-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swift Code"])},
    "ifsc-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFSC Code"])},
    "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYPAL"])},
    "send-payment-to-paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Payment To Paypal ID"])},
    "final-payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Payment Details"])},
    "payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
    "being-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEING PROCESSED"])},
    "payment-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
    "payment-time ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Time "])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])},
    "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
    "company-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Buyer "])},
    "personal-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Buyer "])},
    "exhibition-organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Organizer"])},
    "unlimited-disk-space-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited Disk Space Per Year"])},
    "disk-space-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISKS SPACE FEES"])},
    "sign-into-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Into Your Account"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "remember-me ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me "])},
    "welcome-to-the-world-of-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome To The World Of Art"])},
    "smart-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART"])},
    "1st-choice-of-every-artist-and-art-collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 1st Choice Of Every Artist And Art Collector"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
    "gallery ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery "])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "crowd-funding ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowd Funding "])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
    "find-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Art"])},
    "find-Photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Photo"])},
    "find-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Frame"])},
    "find-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Design"])},
    "find-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Exhibition"])},
    "art-name-type ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art name, Type "])},
    "artist-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Name"])},
    "art-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Categories"])},
    "register-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
    "buy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy "])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
    "exhibit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibit"])},
    "custom-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Order"])},
    "about-smart-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Smart Art"])},
    "art-on-every-step ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art On Every Step "])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedroom"])},
    "toilets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toilets"])},
    "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception"])},
    "meeting-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting Rooms"])},
    "buy-arts-for-your-bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Arts For Your Bedroom"])},
    "buy-arts-for-your-office ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Arts For Your Office "])},
    "rent-items-keep-changing-views ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Items And Keep Changing Your Views "])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DASHBOARD"])},
    "detailed-attractive-smart-dashboard\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Attractive SMART Dashboard\n"])},
    "sell-art-worldwide-at-good-price-with-SMART ART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With SMART ART You Can Sell Your Art World Wide At The Good Price Range "])},
    "smart-art-is-best-platform-to-sell-art-globally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Current Digital World, Smart Art Is The Best Platform For The Artists To Sell Their Art Globally"])},
    "smart-art-customer-base-is-in-more-art-loving-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART’s Customer base is in more the 5 art loving countries like Japan, Vietnam, India and U.S.A."])},
    "increase-in-independent-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Increase In Independent Artists"])},
    "smart-art-is-having-affordable-service-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Is Very Much Affordable As Compare To Other Art Selling Websites Because Of Very Cheap Service Charges "])},
    "popularity-of-pop-up galleries-is-global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Popularity Of Our Pop-Up Galleries Is Global "])},
    "independent-artists-can-reach-broader-audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independent Artists Can Reach A Broader Audience"])},
    "smart-art-gives-fast-customer-support ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Gives You The Smart And Fast Customer Support "])},
    "crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowdfunding"])},
    "new-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New-Registrations"])},
    "products-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Uploaded"])},
    "orders ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders "])},
    "funds-received-by-buyers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Received By Buyers "])},
    "funds-paid-to-owners ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Paid To Owners "])},
    "pending-payments ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payments "])},
    "physical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical"])},
    "virtual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual"])},
    "physical-and-virtual-both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical & Virtual Both"])},
    "feedback ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback "])},
    "smart-art-sellers-benefits ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Sellers Benefits "])},
    "with-smart-art-you-can-create-your- art-exhibition-virtually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead of moving your art piece from one place to another place for the exhibition SMART ART gives you the service to create your art \nexhibition virtually or exhibit your art in other exhibitions "])},
    "smart-art-saves-time-searching-buyer-for-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Saves Your Time In Searching The Best Buyer For Your Art"])},
    "registering-to-smart-art-you-will-be-more-popular-globally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If You Are Registered To SMART ART You Will Be More Popular Globally In Your Art Field."])},
    "smart-art-get-orders-worldwide-customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the help of SMART ART You Are Able To Get The Custom Orders From World Wide Customers Who Appreciate Your Work "])},
    "services ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services "])},
    "sale-auction ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale / Auction "])},
    "buy-rent ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy / Rent "])},
    "custom-order ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Order "])},
    "exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition "])},
    "smart-art-buyers-benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Buyers Benefits"])},
    "smart-art-allows-buy-favourate-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Allows You To Buy Your Favourite Art Across The World At The Good Price Range From Your Place "])},
    "smart-art-best-platform-to-buy-rent-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Current Digital World, Smart Art Is The Best Platform For The Art Lovers To Buy/ Rent Art Without The Limitations And Boundaries Of The Countries "])},
    "popularity-of-pop-up-galleries-is-global ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Popularity Of Our Pop-Up Galleries Is Global So Worldwide Top Artist's Arts Are Available On Smart Art For Buying And Rent "])},
    "instead-of-going-out-buy-or-rent-art-piece ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instead Of Going Out Of Your Place To Exhibit Or Buy Or Rent The Art Piece  "])},
    "with-SMART ART-you-can-give-custom-orders-to-world-wide-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With The Help Of SMART ART You Are Able To Give The Custom Orders To World Wide Best Artist "])},
    "you-can-avail-discount-on-special-events ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With SMART ART You Can Avail 10% Discount On Buying Arts On Special Events "])},
    "art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art "])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "frame ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame "])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "buy-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now "])},
    "rent-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Now "])},
    "exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXHIBITIONS"])},
    "reviews-and-feedback ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews & Feedback "])},
    "add-your-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Your Feedback"])},
    "view-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "jai-bhim-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAI-BHIM House, 1-4-34, SAKAECHODORI, TSURUMI-KU, YOKOHAMA-SHI, KANAGAWA-KEN 230-0038, JAPAN"])},
    "or-write-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or Write Us"])},
    "contact-number\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number\n"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI "])},
    "company-mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is The Company With The Mission Of Developing An Innovative Solutions For Various Problems In Today's World.\nWe Encourage To Make The Things That Change The Way Of Life."])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "smart-planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Planner"])},
    "smart-recruit\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Recruit\n"])},
    "smart-trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Trial"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More…"])},
    "art-exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Exhibitions"])},
    "photo-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Exhibition"])},
    "buy-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/Rent"])},
    "sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale/Auction"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "contacts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts "])},
    "feedback ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback "])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDIA"])},
    "india-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54-Rukmini Villa, 2nd Floor, Dambhare Layout, Near Trimurti Nagar Ring Road, Nagpur 440022, INDIA"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPAN"])},
    "made-by-sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made By Sekai-Ichi"])},
    "rate-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate us "])},
    "rental-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Details "])},
    "rent-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent For "])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTHS"])},
    "minimum-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Months"])},
    "maximum-months ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Months "])},
    "total-rental-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rental Cost "])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK "])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])},
    "search-the-best-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search The Best Art You Want"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "art-gallery\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Gallery\n"])},
    "photo-gallery\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Gallery\n"])},
    "frame-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Gallery"])},
    "showing-arts-for-artist jane-coopers-modify ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Arts For ' Artist Jane Coopers ' Modify "])},
    "all-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Filters"])},
    "art-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Type"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "less-than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less Than "])},
    "add-to-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart "])},
    "go-to-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Cart "])},
    "abstraction\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstraction\n"])},
    "conceptual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceptual"])},
    "pop-culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pop Culture"])},
    "portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portrait"])},
    "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religion"])},
    "canvas-painting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canvas Painting"])},
    "nature\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature\n"])},
    "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forest"])},
    "ocean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocean"])},
    "sports\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports\n"])},
    "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion"])},
    "bollywood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bollywood"])},
    "hollywood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollywood"])},
    "political-etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Political, etc"])},
    "wildlife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wildlife"])},
    "select-all ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all "])},
    "clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "with-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Case"])},
    "without-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without Case"])},
    "back-to-my-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To My Cart "])},
    "already-registered-user ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already Registered User "])},
    "agreement-for-international-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement For International Delivery"])},
    "additional-charges-for-delivery-and-insurance-added-as-per-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Charges For Delivery & Insurance Will Be Added According To The Address You Have Provided & You \nWill Be Notified Soon With Final Purchase Amount For The Payment."])},
    "buy-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Product"])},
    "rent-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Product"])},
    "amazing-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazing Art "])},
    "jane-coopers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jane Coopers"])},
    "request-buy-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Buy Now"])},
    "request-rent-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Rent Now "])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "oil-painting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil Painting"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "sub-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Category"])},
    "physical-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Art"])},
    "weight ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight "])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "art-frame ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art + Frame "])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "washing-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washing Guide"])},
    "frame-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Selection"])},
    "art-available-for-delivery-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Available For Delivery In "])},
    "roll ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll "])},
    "stretch ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stretch "])},
    "roll-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll Delivery"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "ten-percent-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten Percent Discount Will Be Provided To The Quoted Cost For Event Gift Purchases"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "photo-id-proof-showing-the-relevant-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo ID Proof Document Showing The Relevant Date."])},
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
    "file-size-must-be-less-than                  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(The File Size Must Be Less Than 10 MB)"])},
    "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "all-fields-are-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(All fields Are Mandatory)"])},
    "first-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name "])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "nagpur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagpur"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "added-in-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added In Cart "])},
    "maharashtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maharashtra"])},
    "query-about-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query-About-Product"])},
    "product-reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Reviews"])},
    "request-buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Buy"])},
    "request-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Rent"])},
    "see-more-like-this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More Like This"])},
    "product-is-added-to-cart-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Product Is Added To The Cart Successfully"])},
    "your-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cart "])},
    "place-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
    "you-need-to-enter-delivery-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Need To Enter The Delivery Cost And Insurance Cost To Confirm The Order"])},
    "product-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Cost "])},
    "delivery-charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Charge"])},
    "insurance-charge ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Charge "])},
    "product-rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Rating"])},
    "track-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track Order"])},
    "download-receipt ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Receipt "])},
    "return-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Item"])},
    "remove-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Product"])},
    "engagement ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement "])},
    "wedding-anniversary ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding Anniversary "])},
    "company-establishment-anniversary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Establishment Anniversary"])},
    "remembrance-day ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remembrance Day "])},
    "back-to-my-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To My Cart "])},
    "ordered-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered By"])},
    "frame-material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Material"])},
    "rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Date"])},
    "for-artist-photographer-designer-architect-frame-vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Artist Photographer Designer Architect Frame Vendor"])},
    "return-policy-terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Policy / Terms & Conditions "])},
    "once-the-order-is-placed-buyer-can-return-at-following-cost\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once The Order Is Placed, For A Valid Reason, Buyer Can Return The Product Within 7 Days After The Delivery At Following Cost, Owned By The Buyer.\n"])},
    "sellers-dispatch-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sellers Dispatch Charges"])},
    "seller-insurance-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller Insurance Charges"])},
    "return-delivery-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Delivery Charges"])},
    "any-damage-charges-of-return-at-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any Damage Charges At The Time Of Return At Source."])},
    "return-due-to-damage-from-seller-charges-paid-by-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, If The Return Is Due To The Damage From Seller Side Before Delivery To The Buyer, All The Charges Will Be Borne By The Seller."])},
    "terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions "])},
    "i-agree-ten-percent-discount-will-be-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Agree That 10% Discount Will Be Provided To The Quoted Cost For Event Gift Purchases By The Buyers."])},
    "i-agree-to-return-policy-terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Agree To Return Policy And All The Terms & Conditions "])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "company-buyer-personal-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Buyer Personal Buyer"])},
    "i-agree-to-return-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Agree To Return Policy "])},
    "make-payment-within-ten-days-to-bank-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Make Payment Within 10 Days To Below Bank Account"])},
    "success ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! "])},
    "your-payment-done-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Payment Done Successfully. Please Wait, We Will Redirect You On Tenancy Page "])},
    "send-reset-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reset Link"])},
    "reset-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password "])},
    "enter-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password"])},
    "re-enter-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Enter New Password"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "todays-offers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today’s Offers "])},
    "offer-ends-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Ends In "])},
    "discount-on-buy-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount On Buy Product"])},
    "to-access-all-the-services-in-smart-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Access All The Services In Smart Art "])},
    "sale-auction-all-types-of-art-worldwide ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale / Auction Your All Type Of Art, Photograph & Design World Wide "])},
    "buy-rent-art-across-world-from-your-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy / Rent Art, Photograph & Design Across The World From Your Place"])},
    "place-custom-order-and-get-art-at-your-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place The Custom Order And Get The Custom Art, Photograph Or Design At Your Place"])},
    "without-going-feel-real-exhibition-at-virtual-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without Going Out Of Your Place Feel The Real Exhibition At The Smart Art Virtual Exhibition "])},
    "top-trending-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Trending Artists"])},
    "followed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followed"])},
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "abstraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstraction"])},
    "geomatric-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geometric Art"])},
    "find-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Art\n"])},
    "owner-will-let-you-know-soon-total-cost-of-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Will Let You Know Soon The Total Cost Of Purchase"])},
    "feedback-rank-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback/ Rank to Seller"])},
    "submit-product-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Product Review"])},
    "cancel-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
    "delivery-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery On "])},
    "purchase-equest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Request"])},
    "order-cancelled ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Cancelled "])},
    "total-cost-disclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost Disclose"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "order-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirmed"])},
    "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
    "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "guy-hawkins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guy Hawkins"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers"])},
    "sold-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold Arts"])},
    "lease-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lease Arts"])},
    "sold-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold Arts"])},
    "lease-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lease Arts"])},
    "pending-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Arts"])},
    "top-trending-photographers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Trending Photographers"])},
    "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food"])},
    "trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trees"])},
    "animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals"])},
    "actors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actors"])},
    "actress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actress"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
    "sold-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold Photos"])},
    "lease-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lease Photos"])},
    "pending-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Photos"])},
    "top-trending-frame-vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Trending Frame Vendors"])},
    "top-trending-designers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Trending Designers"])},
    "design-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Gallery"])},
    "sold-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold Designs"])},
    "lease-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lease Designs"])},
    "pending-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Designs"])},
    "hey-friends ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey Friends!"])},
    "are-you-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Artists?"])},
    "looking-for-earning-from-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking For Earning From Your Art? "])},
    "not-getting-clients-for-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Getting Clients For Your Art! "])},
    "not-getting-proper-promotion-of-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Getting Proper Promotion Of Your Art! "])},
    "not-having-proper-identity-in-the-art-world ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Having Proper Identity In The Art World "])},
    "want-the-best-value-of-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want The Best Value Of Your Art "])},
    "here-is-solution-of-every-question ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurray!\nHere Is The Solution Of Your Every Question "])},
    "best-platform-for-artists-and-art-collectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Of The Best Platform For Artists & Art Collectors"])},
    "smart-art-provides-worldwide-customers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Provides You The Worldwide Customers "])},
    "smart-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Dashboard"])},
    "smart-art-detailed-attractive-smart-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Detailed Attractive SMART Dashboard"])},
    "world-wide-exhibitions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Wide Exhibitions "])},
    "smart-art-provides-special-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Gives You The Special Service Of Virtual Exhibition And Physical Exhibition Organising Service. Where You Can Exhibit Your Art Or Allow Others To Exhibit Their Arts In Your Exhibition. You Are Allowed To Organise The Virtual Exhibition From Your Place Virtually. You Will Get The World Wide Artists And Art Loving Customers To Buy Or Rent Art & Exhibit In Your Exhibition "])},
    "worldwide-art-buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World Wide Art Buyers"])},
    "smart-art-leading-player-of-art-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Is The Leading Player Of Art World"])},
    "smart-art-have-worldwide-art-collector-customer-base ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Have World Wide Art Collector Customer Base "])},
    "more-than-one-million-customers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Than 1 Million Customers "])},
    "thousand-plus-arts-solld-every-years ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+Arts Sold Every Years "])},
    "thousand-new-artists-join-smart-art-every-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ New Artists Join Smart Art Every Year"])},
    "new-buyers-visit-smart-every-week ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5000 Above New Buyers Visit Smart Art Every Week "])},
    "providing-number-one-service-to-clients ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Is Providing No:1 Service To Their Clients "])},
    "worlds-number-one-art-business-website ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["World's No. 1 Art Business Website "])},
    "successfully-working-in-all-asian-countries-and-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Working In All Asian Countries & More "])},
    "are-you-worried ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Worried?"])},
    "worried-about-searching-for-best-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worried About Searching For A Best Art Piece  For Your Place?"])},
    "worried-about-buying-art-online ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worried About Buying Art Online?"])},
    "not-having-trust-on-art-bought-online ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Having Trust On The Art Bought Online! "])},
    "not-having-proper-knowledge-of-art-business ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Having Proper Knowledge Of Art Business?"])},
    "looking-for-earning-from-art-business ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking For Earning From Art Business? "])},
    "want-to-create-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want To Create Exhibition?"])},
    "get-graphical-view-of-daily-uploaded-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Get The Graphical View Of Daily Uploaded Arts"])},
    "will-get-information-on-art-bought-and-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Get The Information On Art Bought And Rented In Graphical View "])},
    "detailed-information-about-the-followed-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Information About The Followed Artists "])},
    "notifications-and-ending-rental-date-reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications And Ending Rental Date Reminders"])},
    "newly-uploaded-arts-from-followed-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newly Uploaded Arts Regularly From The Followed Artists "])},
    "best-offers-regularly ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Offers Regularly "])},
    "discounts-on-special-events-and-reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts On Special Events & Reminders On Events"])},
    "smart-calender-dashboard-to-remind-every-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Calendar In Dashboard To Remind Every Event Of Your Life"])},
    "best-arts-from-the-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Arts From The World"])},
    "smart-art-is-the-leading-player-of-art-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART \nIs The Leading Player Of Art World"])},
    "smart-art-have-world-wide-artists-best-arts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART Have World Wide Artist's Best Arts "])},
    "more-than-ten-thousand-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Than 10.000 Artists"])},
    "hundred-plus-arts-uploads-every-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100+ Plus Arts Uploads Every Month"])},
    "thousand-plus-new-artists-join-every-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ New Artists Join Smart Art Every Year"])},
    "smart-art-providing-number-one-service-to-clients ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Is Providing No 1 Service To Their Clients "])},
    "worlds-number-one-art-business-website ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worlds No 1 Art Business Website "])},
    "successfully-working-in-all-asian-countries-and-more ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Working In All Asian Countries & More "])},
    "hey-brother ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey Brother "])},
    "any-idea-where-can-get-custom-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did You Have Any Idea Where Can Get The Custom Art ?"])},
    "want-to-gift-art-frame-on-birthday ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Want To Gift An Art Frame To Mummy On Her Birthday "])},
    "have-searched-a-lot-about-custom-arts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Have Searched Alot About The Custom Arts "])},
    "but-they-are-charging-a-lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["But They Are Charging A Lot"])},
    "will-you-please-help-me ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will You Please Help Me?"])},
    "best-solution-for-your-problem  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hey Don’t Worry Dear. I Have The Best Solution For Your Problem  "])},
    "best-online-platform-for-all-type-of-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Best Online Platform For All Type Of Arts"])},
    "smart-art-provides-custom-art-from-top-artists-at-discount-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Provides You The Custom Art From Top Artists In Discount Price For Special Events Like Birthday, Anniversary Etc..  "])},
    "order-custom-art-from-top-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order The Custom Art From Top Artists"])},
    "order-custom-photo-by-top-photographers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order The Custom Photo By The Top Photographers "])},
    "order-custom-design-by-top-designers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order The Custom Design By The Top Designers "])},
    "digital-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Art"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
    "art-washable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Washable "])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "upload-wash-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Wash Guide"])},
    "attach-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Image"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces"])},
    "art-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Description"])},
    "abc-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABC Art "])},
    "apply-own-frame ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Own Frame "])},
    "upload-frame-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Frame Image"])},
    "art-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Size"])},
    "frame-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Size"])},
    "minimum-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Years"])},
    "maximum-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Years"])},
    "international-orders-acceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Orders ACCEPTABLE? "])},
    "sale-auction-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale/ Auction Cost "])},
    "sale-cost-including-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Cost (Including Frame)"])},
    "service-charge ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge "])},
    "thirty-percent-of-sale-cost-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30% Of Sale Cost (Buyer)"])},
    "publishing-budget-to-custom-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing Budget To Custom Order"])},
    "upload-reference-file \n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Reference File \n"])},
    "terms ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms "])},
    "this-art-not-published-on-other-platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Art Is Not Published In Any Other Online Platform For Auction Or Sale. "])},
    "same-art-not-uploaded-with-different-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same ART Will Not Be Uploaded With Different Price Range."])},
    "this-art-will-not-be-published-on-other-platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Art Will Be Published In Our Platform For At Least 12 Months. During This Time, This Same ART Won’t Be Published On Any Other Online Auction Or Sale Platform. Not With Standing To The Compliance May Result Into The Ban Of Membership And Penalty."])},
    "i-accept-the-above-terms-to-upload-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Accept the above Terms & Conditions To Upload Art To Smart Art"])},
    "place-order-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order On "])},
    "open-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Auction"])},
    "search-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Artist"])},
    "search-by-art-name-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search By Art Name, Type, Artist Name "])},
    "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget"])},
    "showing-arts-for-the-budget  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Arts For ' Budget 500 $ ' Modify "])},
    "place-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
    "delivery-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery In "])},
    "softcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softcopy"])},
    "hardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardcopy"])},
    "physical-design-washable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Design Washable "])},
    "architecture ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architecture "])},
    "interior-design ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interior Design "])},
    "exterior-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exterior Design"])},
    "hall-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall Design"])},
    "drawing-room-design ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawing Room Design "])},
    "office-room-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Room Design"])},
    "design-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Description"])},
    "design-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Size"])},
    "event-details-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event details (Discount)"])},
    "exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition "])},
    "upcoming-exhibiton ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Exhibition "])},
    "art-exhibiton ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Exhibition "])},
    "as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Attendee"])},
    "as-exhibitor ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Exhibitor "])},
    "physical-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Fees "])},
    "virtual-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Fees "])},
    "arts-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Registered"])},
    "last-date-to-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date To Register"])},
    "apply-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply As Exhibitor"])},
    "apply as-physical-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply As Physical Attendee"])},
    "apply-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply As Virtual Attendee"])},
    "cancel-your-subscription-as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Cancel Your Subscription As Attendee Before 3 Days Of The Date Of Exhibition "])},
    "arts-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Allowed"])},
    "subscribed-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Attendees"])},
    "subscribed-exhibitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Exhibitors"])},
    "exhibition-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Name"])},
    "exhibition-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Category"])},
    "exhibition-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Type"])},
    "exhibition-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Address"])},
    "organizer-e-mail-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer E-Mail ID"])},
    "organizer-tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer Tel"])},
    "virtual-exhibition-online-link-to-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Exhibition Online Link To Join"])},
    "note-the-link-exhibitors-attendees-by-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note-The Link Is Sent To Exhibitors And Attendees By Email  On The Exhibition Day And Will Be Visible In The Exhibition On The Day Of The Exhibition"])},
    "exhibition-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Date"])},
    "exhibitors-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibitors Subscribed"])},
    "showing-enteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing 1 To 10 Of 30 Entries "])},
    "why-smart-art-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Smart Art Crowdfunding?"])},
    "smart-crowdfunding-process-of-raising-funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Crowdfunding Is The Process Of Raising Funds With The Help Of People Across The World, Using Donation Platforms As The Medium. Crowdfunding On Smart Art Helps Fund Artists. A Fundraising Via Smart Crowdfunding Platforms Requires No Payback And Is For Everyone - From An Art Student Senior Artists. Our Smart Crowdfunding Help The New Students Across The World Who Want To Learn Or Earn From The Art. Our Smart Crowdfunding Influence And Motivate The Artist Who Are Facing Problems. Be The Part Of Smart Crowdfunding Fund Raiser And Help The Artists Community In The World. "])},
    "your-art-need-urgent-financial-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Art Is On The Way To Death Need Urgent Financial Help"])},
    "raise-money-online-with-free-crowdfunding ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raise Money Online With Smart Art Free Crowdfunding "])},
    "free-fund-raising-platform ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Fund Raising Platform "])},
    "above-lakhs-generous-donors ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above 10 Lakhs Generous Donors "])},
    "lots-of-successful-fund-raised ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above 200 Successful Fund Raised "])},
    "fast-fundraising ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Fundraising "])},
    "donors-from-countries ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donors From 10 Countries "])},
    "generous-donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generous Donors"])},
    "fundraisers-created-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraisers Created Successfully"])},
    "fastest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastest 100K JPY"])},
    "raised-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raised In 24 hrs"])},
    "smart-art-crowdfunding-projects ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Crowdfunding Projects "])},
    "bunch-of-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunch of Nature "])},
    "arts-available-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Available For "])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
    "check-profile ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Profile "])},
    "the-project-consist-of-authentic-art-peices ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Project Consist Of 3 Art Pieces Created By The Artist First Name Last Name On The Topic Of Nature. Arts Available With The Authentication Certificate Bye The Artist "])},
    "green-heaven ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green-Heaven "])},
    "greenery-in-the-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Greenery In The Nature "])},
    "the-flow-of-river-in-the-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Flow Of River In The Nature "])},
    "in-the-heaven  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In The Heaven  "])},
    "cherry-blossom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherry Blossom"])},
    "the-art-shows-the-cherry-blossom ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Art Shows The Cherry Blossom "])},
    "donated ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donated "])},
    "total-amount-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount Requested"])},
    "smart-art-successful-crowdfunding-projects ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Successful Crowdfunding Projects"])},
    "crowdfunding-is-successufully-closed ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The crowdfunding is successufully closed "])},
    "do-not-have-account ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don’t have Account?"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased"])},
    "art-piece ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Piece  "])},
    "flow-in-heaven ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow In Heaven "])},
    "art-shows-greenery-in-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Art Shows The Greenery In The Nature "])},
    "art-completion-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Completion Schedule"])},
    "art-normal-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Normal Cost"])},
    "art-cost-in-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Cost In Crowdfunding"])},
    "delivery-date   ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date   "])},
    "purchase-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Pending"])},
    "purchase-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Now"])},
    "payment-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Option"])},
    "forums-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forums Published"])},
    "members-engaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members Engaged"])},
    "create-new-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Forum"])},
    "forum-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Owner"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "forum-created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Created Date"])},
    "forum-updated-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Updated Date"])},
    "edit-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Forum"])},
    "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies"])},
    "forum-created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Created Date"])},
    "post-message ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Message "])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
    "send ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send "])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "Forum-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Title"])},
    "forum-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Type"])},
    "select-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Users"])},
    "internal-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal forum"])},
    "forum-detailed-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum Detailed Message"])},
    "exhibitions-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Is The Only Place Where You Can Explore Unique Arts And Photographs By Well Known Artists, Photographers And Designers. You Will Have An Opportunity To Explore Various Categories Of Arts Like Nature, Ocean, Hollywood, Bollywood, Sports, Fashion, Wildlife Etc. Visit Our Art Exhibitions. And Discover Its Extraordinary Art Collection. An Eclectic Path Between Artistic Masterpieces And Curiosities. Smart Art Gives You An Option To Attend The Exhibition Physically Or Virtually. Virtual Exhibition Is The Special Service Where You Can Attend The Exhibition At Your Place. You Will Get The Real Feel Of Exhibition And All The Arts And Products At Your Fingertip. You Can Buy Them Or Rent Them While Navigating Through The Virtual Exhibition. "])},
    "smart-art-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART is the platform for the art lovers to connect and appreciate art globally. SMART ART provide the world-wide Famous Arts For sale and Rent. SMART ART provide Monthly Exhibitions for artiest to market art worldwide. We have customer base in all developing and art loving countries. You can get all types of arts at one place at SMART ART."])},
    "my-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tasks"])},
    "art-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Library"])},
    "art-photo-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art / Photo Exhibition"])},
    "disk-quota-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk Quota - FREE"])},
    "purchase-disk-quota-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Disk Quota Now"])},
    "total-revenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Revenues"])},
    "payment-released-by-sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Released By SEKAI-ICHI"])},
    "payment-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Pending"])},
    "my-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Crowdfunding"])},
    "my-purchased-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Purchased Arts"])},
    "funds-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Donated"])},
    "funds-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Received"])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payment"])},
    "amount-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Donated"])},
    "arts-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Donated"])},
    "money-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Saved"])},
    "as-organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Organizer"])},
    "mizuho-bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mizuho Bank"])},
    "not-applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Applicable"])},
    "artist-photographer-vendor-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist/Photographer/Vendor/Organization"])},
    "decline-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline Discount"])},
    "want-to-decline-request-for-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Decline The Request For Discount"])},
    "describe-reason-to-decline-discount-applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe The Reason To Decline The Discount Applied"])},
    "id-proof-showing-date-is-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided Photo ID Proof Document Showing The Relevant Date Is Not Valid"])},
    "order-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order’s Payment Status"])},
    "tracking-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking URL"])},
    "tracking-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking Number"])},
    "expected-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected Delivery Date"])},
    "paid-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Seller"])},
    "sekai-ichi-payment-details-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Payment Details To Seller"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "request-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Payment"])},
    "purchase-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Request"])},
    "revenue-from-arts-rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue From Arts Rented"])},
    "rent-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Request"])},
    "request-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Payment"])},
    "payment-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Request"])},
    "download-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Receipt"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "return-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Item"])},
    "received-different-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Received Different Item"])},
    "reason-for-returning-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason For Returning The Item"])},
    "art-rent-session-is-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Rent Session Is Expired"])},
    "under-lease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under Lease"])},
    "extend-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend Rent"])},
    "custom-order-placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Order Placed"])},
    "apply-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Auction"])},
    "artist-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Accepted"])},
    "order-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order In Progress"])},
    "order-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Completed"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "deposit-refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Refunded"])},
    "custom-order-placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Order Placed"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Request"])},
    "rent-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Request"])},
    "order-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Confirmed"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
    "paid-to-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Artist"])},
    "enter-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Virtual Exhibition"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "funds-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Donated"])},
    "funds-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds Received"])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Payment"])},
    "my-purchased-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Purchased Arts"])},
    "art-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Shipped"])},
    "my-donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Donation"])},
    "amount-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Donated"])},
    "arts-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts Donated"])},
    "money-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Saved"])},
    "return-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Accepted"])},
    "total-expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Expenses"])},
    "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
    "purchase-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Now"])},
    "add-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Art"])},
    "edit-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Art"])},
    "once-order-placed-with-valid-reason-can-return-product-within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once The Order Is Placed, For A Valid Reason, You Can Return The Product Within"])},
    "days-after-delivery-at-following-cost-owned-by-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days After The Delivery At Following Cost Owned By The Buyer."])},
    "owners-dispatch-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owners Dispatch Charges"])},
    "owner-insurance-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Insurance Charges"])},
    "return-delivery-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Delivery Charges"])},
    "art-already-used-for-order-placement-it-can-not-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Art Is Already Used For Order Placement. It Can Not Be Deleted"])},
    "abstraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstraction"])},
    "international-orders-acceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Orders Acceptable?"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sports-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports Photography"])},
    "still-life-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still Life Photography"])},
    "editorial-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editorial Photography"])},
    "architectural-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Architectural Photography"])},
    "due-to-some-reasons-exhibition-postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due To Some Reasons Exhibition Is Postponed"])},
    "add-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Frame"])},
    "purchase-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Now"])},
    "attach-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Image"])},
    "publishing-amount-to-sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing Amount To SaleAuction"])},
    "gallery-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery Frame"])},
    "modern-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern Frame"])},
    "floating-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floating Frame"])},
    "deep-set-frames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deep-Set Frames"])},
    "canvas-prints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canvas Prints"])},
    "tabletop-frames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabletop Frames"])},
    "photo-holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Holders"])},
    "add-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Design"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "landscape-architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landscape Architecture"])},
    "industrial-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial Design"])},
    "fashion-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion Design"])},
    "engineering-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineering Design"])},
    "software-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Design"])},
    "user-interface-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Interface Design"])},
    "user-experience-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Experience Design"])},
    "want-to-delete-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Design"])},
    "add-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exhibition"])},
    "applied-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied As Exhibitor"])},
    "cancel-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Subscription"])},
    "applied-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied As Virtual Attendee"])},
    "applied-as-physical-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied As Physical Attendee"])},
    "enter-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Virtual Exhibition"])},
    "exit-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Virtual Exhibition"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Request"])},
    "you-can-edit-delete-exhibition-as-organiser-before-ten-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Edit / Delete Your Exhibition As Organiser Before 10 Days Of The Date Of Exhibition"])},
    "design-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Exhibition"])},
    "cancel-exhibitor-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Exhibitor Subscription"])},
    "want-to-cancel-subscription-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Cancel Your Subscription For The Exhibition"])},
    "reason-of-cancelling-subscription-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What Is The Reason Of Cancelling Your Subscription For The Exhibition?"])},
    "request-to-cancel-subscription-sent-to-superadmin-you-will-be-informed-after-accepting-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Request To Cancel Your Subscription For The Exhibition Is Sent To The Super Admin.\n You Will Be Informed After Accepting The Request"])},
    "bhim-upi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhim UPI"])},
    "net-banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Banking"])},
    "security-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Code"])},
    "request-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Crowdfunding"])},
    "request-crowd-funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Crowd Funding"])},
    "organizer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer Name"])},
    "artist-smart-art-profile-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Smart Art Profile Link"])},
    "fundraising-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraising Title"])},
    "fundraising-cover-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundraising Cover Photo"])},
    "domestic-delivery-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domestic Delivery Cost"])},
    "art-completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Completion Date"])},
    "normal-sale-auction-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Sale Auction Cost"])},
    "crowdfunding-sale-auction-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowdfunding Sale Auction Cost"])},
    "normal-rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Rental Cost (Per Month)"])},
    "crowdfunding-rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowdfunding Rental Cost (Per Month)"])},
    "publishing-crowdfunding-amount-to-sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing Crowdfunding Amount To SaleAuction"])},
    "upload-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
    "add-copy-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Copy Art Piece"])},
    "add-more-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add More Art Piece"])},
    "flow-in-heaven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow In Heaven"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone No"])},
    "payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
    "art-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Status"])},
    "return-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Accepted"])},
    "return-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Shipped"])},
    "return-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Delivered"])},
    "shipping-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Details"])},
    "my-purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Purchased"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased"])},
    "my-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Details"])},
    "all-details-should-be-same-as-mentioned-in-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Details Should Be Same As Mentioned In My Profile"])},
    "regular-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Cost"])},
    "crowdfunding-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crowdfunding Cost"])},
    "revenue-spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue Spent"])},
    "total-amount-spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount Spent"])},
    "payment-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Request"])},
    "paid-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Buyer"])},
    "projects-donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects Donation"])},
    "total-amount-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount Donated"])},
    "donated-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donated Products"])},
    "payment-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Request"])},
    "item-returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Returned"])},
    "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
    "order-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Requested"])},
    "additional-charges-for-delivery-insurance-will-be-notyfied-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Charges For Delivery & Insurance Will Be Added According To The Address You Have Provided & You Will Be Notified Soon With Final Purchase Amount For The Payment."])},
    "refund-deposit-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Deposit Policy"])},
    "return-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Order"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "print-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Details"])},
    "total-rental-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Rental Cost"])},
    "renew-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew Rent"])},
    "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
    "deposit-refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Refunded"])},
    "return-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Item"])},
    "order-renew-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order’s Renew Payment Status"])},
    "deposit-refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Refunded"])},
    "sekai-ichi-rental-deposit-payment-refund-details-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Rental deposit Payment Refund Details To Buyer"])},
    "order-will-be-confirmed-after-artist-publish-delivery-insurance-cost-to-confirm-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Order Will Be Confirmed After Artist Publish The Delivery Cost And Insurance Cost To Confirm The Order"])},
    "auction-applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction Applied"])},
    "artists-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artists Name"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
    "accpted-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted Artist"])},
    "order-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order In Progress"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "paid-to-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Artist"])},
    "exhibition-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Name"])},
    "exhibition-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Type"])},
    "cancel-virtual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Virtual Subscription"])},
    "cancel-physical-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Physical Subscription"])},
    "enter-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Virtual Exhibition"])},
    "presenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenting"])},
    "organised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organised"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "can-cancel-subscription-as-attendee-before-3-days-of-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Cancel Your Subscription As Attendee Before 3 Days Of The Date Of Exhibition"])},
    "exhibition-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Category"])},
    "exhibition-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Address"])},
    "organizer-tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer Tel"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "organizer-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer E-Mail ID"])},
    "virtual-exhibition-online-link-to-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Exhibition Online Link To Join"])},
    "exhibition-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Date"])},
    "central-time-us-canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Time (US & Canada)"])},
    "subscribed-exhibitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Exhibitors"])},
    "name-on-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name On Card"])},
    "being-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being Processed"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Request"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "virtual-attendee-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Attendee Fees"])},
    "physical-attendee-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Attendee Fees"])},
    "cancel-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Subscription"])},
    "due-to-some-reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due To Some Reasons ....."])},
    "un-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un-Subscribed"])},
    "proceed-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed To Pay"])},
    "my-funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Funding"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased"])},
    "my-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Purchase"])},
    "art-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Completed"])},
    "smart-art-welcomes-you-to-world-of-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art Welcomes You To The World Of Art One Of The Best Platform For Artists & Art Collectors"])},
    "admin-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Name"])},
    "exhibit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibit"])},
    "art-photo-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art / Photo Exhibition"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "my-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tasks"])},
    "roles-and-permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles & Permissions"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "art-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Library"])},
    "photo-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Library"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "new-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Registrations"])},
    "products-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Uploaded"])},
    "frame-vendors ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Vendors "])},
    "revenue-earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue Earned"])},
    "payment-released-by-sekai-ichi ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Released By SEKAI-ICHI"])},
    "payment-pending ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Pending "])},
    "custom-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Art "])},
    "art-completer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Completer"])},
    "feedback-reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback/Reviews"])},
    "errors-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors Log"])},
    "activiy-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity log"])},
    "email-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Templates"])},
    "master-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Data"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localization"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name "])},
    "role ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role "])},
    "super-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
    "login-email ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Email "])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "personal-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Details "])},
    "address-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address (Permanent)"])},
    "address-line-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])},
    "address-line-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])},
    "address-line-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 3"])},
    "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "add-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Photo"])},
    "editable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editable "])},
    "change-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password "])},
    "enter-old-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Old Password "])},
    "enter-new-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter New Password "])},
    "re-enter-new-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Enter New Password "])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRIES"])},
    "art-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Name"])},
    "date-range ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Range "])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "payment-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Request"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "paid-to-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Buyer "])},
    "paid-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Seller"])},
    "cancel-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
    "product-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Details "])},
    "with-case ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Case "])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "attached-id-proof ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached Id Proof "])},
    "rate-this-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate This Product"])},
    "payment-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Requested"])},
    "confirm-order ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order "])},
    "metallic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metallic"])},
    "orders-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order’s Payment Status"])},
    "payment-done-from ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Done From "])},
    "first-name-last-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name Last Name "])},
    "payment-date-and-time ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date & Time "])},
    "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
    "track-order ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track Order "])},
    "delivery-status ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Status "])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "courrier-company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courrier Company Name"])},
    "attached-photograph-of-delivered-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached Photograph Of Successfully Delivered Product"])},
    "delivered ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered "])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
    "once-order-placed-return-product-within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once The Order Is Placed, You Can Return The Product Within "])},
    "seven-days-after-delivery-owned-by-buyer\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Days After The Delivery At Following Cost Owned By The Buyer.\n"])},
    "reason-for-returning-the-item ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason For Returning The Item "])},
    "want-the-correct-art-back ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want The Correct Art Back "])},
    "want-the-money-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want The Money Back"])},
    "sekai-ichi-rental-deposit-payment-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Rental Deposit Payment Details To Buyer"])},
    "sekai-ichi-payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI PAYMENT DETAILS"])},
    "i-received-different-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I received Different Item"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "artist-returned-item ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Returned Item "])},
    "sekai-ichi-payment-details-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Payment Details To Seller"])},
    "purchase-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Requested"])},
    "previous-rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Rental Date"])},
    "future-rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Rental Date"])},
    "orders-renew-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order/’s Renewed Payment Status"])},
    "renewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewed"])},
    "artist-not-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Not Accepted"])},
    "product-budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Budget"])},
    "custom-order-placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Order Placed"])},
    "auction-applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auction Applied"])},
    "artist-accepted ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Accepted "])},
    "order-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order In Progress"])},
    "order-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Completed"])},
    "paid-to-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Artist"])},
    "reference-images ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Images "])},
    "art-orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Orientation"])},
    "applied-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied Artists"])},
    "bid-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid Price"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "order-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order In Progress"])},
    "order-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Completed"])},
    "completed-art-images ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Art Images "])},
    "rental-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Deposit"])},
    "rent-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Request"])},
    "under-lease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under Lease"])},
    "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
    "fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees "])},
    "accept-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Exhibition"])},
    "reject-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Exhibition"])},
    "presenting ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenting "])},
    "enter-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter As Exhibitor"])},
    "enter-as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter As Attendee"])},
    "crowd-funding-is-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Crowd Funding Is Requested"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
    "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
    "audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit"])},
    "financial-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year"])},
    "flexible-duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Duration"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment-Type"])},
    "reference ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference "])},
    "credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Amount"])},
    "payment-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
    "feedback-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback By"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "buy-corporate-membership-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Corporate Membership Plan"])},
    "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Role"])},
    "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Name"])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "edited-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited Date"])},
    "copy-existing-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Existing Role"])},
    "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "frame-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Library"])},
    "design-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Library"])},
    "finance-and-audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance & Audit"])},
    "promotions-messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions (Messaging)"])},
    "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referrals"])},
    "error-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Logs"])},
    "activity-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Logs"])},
    "contact-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us "])},
    "custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])},
    "view-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Role"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Role"])},
    "delete-role ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Role "])},
    "want-to-delete-the-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Role"])},
    "role-in-use-can-not-delete-this-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Role Is In Use, You Can Not Delete This Role."])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
    "user-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User List"])},
    "internal-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Users"])},
    "subscribed-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Users"])},
    "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "company-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name "])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "ui-applicable-to-users-and-buyers-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This UI Is Applicable For Internal Users & Buyer Only"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "company-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Details "])},
    "ui-is-applicable-to-company-and-personal-buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This UI Is Applicable For Internal Users & Buyers (Company And Personal Buyer) Only But \nBuyer will be listed in Subscribed User"])},
    "ui-is-applicable-to-subscribed-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This UI Is Applicable For Subscribed Users (Artists, Photographer, Designer, Architect, Exhibition Organizer) Except Buyers "])},
    "bank-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account Name"])},
    "bank-branch ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Branch "])},
    "bank-passbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Passbook"])},
    "file-size-must-be-less-than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The File Size Must Be Less Than "])},
    "mb-only ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB Only "])},
    "paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal ID"])},
    "my-bank-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Bank Details "])},
    "total-revenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL Revenues"])},
    "payment-released-by-sekai-Ichi ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Released By SEKAI-ICHI"])},
    "payment-pending ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Pending "])},
    "download-sales-record ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Sales Record "])},
    "disk-quota-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISK QUOTA STATUS"])},
    "disk-quota-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk Quota: FREE"])},
    "used-space ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used Space "])},
    "free-space  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Space  "])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
    "disk-quota-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk Quota: PAID"])},
    "unlimited-disk-space ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited Disk Space "])},
    "days-left-expires-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days Left. Expires On "])},
    "optional-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Services"])},
    "purchased-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased Services"])},
    "disk-space-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk Space Fees "])},
    "total-amount-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount Paid"])},
    "this-ui-is-applicable-for-external-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This UI Is Applicable For External Users (Artists, Photographers, Designers Etc.) Except Buyers "])},
    "view-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Art"])},
    "purchase-disk-quota-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Disk Quota Now"])},
    "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
    "renew ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew "])},
    "ready-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready For "])},
    "sale-cost-including-frame\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale Cost (Including Frame)\n"])},
    "service-charge ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge "])},
    "of-sale-cost-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of Sale Cost (Buyer)"])},
    "you-receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Receive"])},
    "publishing-amount-to-sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing Amount To Sale/Auction"])},
    "rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Cost (Per Month)"])},
    "rental-cost-including-frame\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Cost (Including Frame)"])},
    "service-charge-for-buyer\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge (Buyer)\n"])},
    "refund-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Policy "])},
    "deposit-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Cost "])},
    "upload-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
    "service-charge-will-be-charged-if-delivery-charges-are-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge Will Be Charged On Sale/Rent/Custom Order Cost. If The Delivery And Insurance Charges Are More Than "])},
    "for-domestic-and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Domestic And"])},
    "promotion-offer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Offer "])},
    "give-discount-to-promote-profile ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Give Discount To Your Buyers To Promote Your Profile "])},
    "non-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Non Mandatory)"])},
    "discount-percentage ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Percentage "])},
    "discount-you-are-providing-on-sale-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Discount You Are Providing Is On Your Sale Cost / Rent Cost "])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "delete-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Art"])},
    "want-to-delete-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Art"])},
    "explain-reason-deleting-the-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain The Reason For Deleting The Art"])},
    "exhibition-is-postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due To Some Reasons Exhibition Is Postponed"])},
    "event-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Photography"])},
    "fashion-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fashion Photography"])},
    "nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature "])},
    "frame-vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame Vendor"])},
    "delete-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Photo"])},
    "want-to-delete-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Photo"])},
    "explain-the-reason-for-deleting-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain The Reason For Deleting The Photo"])},
    "view-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Frame"])},
    "wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood"])},
    "cedar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cedar"])},
    "spruce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spruce"])},
    "pine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pine"])},
    "photo-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Description"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frame"])},
    "delete-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Frame"])},
    "want-to-delete-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Frame"])},
    "explain-reason-for-deleting-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explain The Reason For Deleting The Frame"])},
    "exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibitions"])},
    "add-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exhibition"])},
    "organised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organised"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
    "edit-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Exhibition"])},
    "delete-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Exhibition"])},
    "last-date-to-register-dd-mm-yyyy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date To Register: DD-MM-YYYY"])},
    "enter-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter As Virtual Attendee"])},
    "enter-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter As Exhibitor"])},
    "can-edit-delete-exhibition-as-organiser-before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Edit /Delete Your Exhibition As Organiser Before "])},
    "days-of-the-date-of-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days Of The Date Of Exhibition "])},
    "organizer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer "])},
    "exhibition-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Name "])},
    "exhibition-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Fees "])},
    "service-fee-charged-on-total-revenue-generated-by-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge Will Be Charged By SEKAI-ICHI On The Total Revenue Generated By The Exhibition "])},
    "exhibitors-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibitor's Cost "])},
    "attendees-virtual-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendees Virtual Cost"])},
    "attendees-physical-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendees Physical Cost"])},
    "select-start-date-time-and-timezone-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Start Date, Time And Timezone For The Exhibition"])},
    "select-end-date-time-and-timezone-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select An End Date, Time And Timezone For The Exhibition"])},
    "maximum-exhibitors-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Exhibitors Allowed"])},
    "no-restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Restrictions"])},
    "maximum-subscribers-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Subscribers Allowed"])},
    "virtual-exhibition-preview-attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Exhibition Preview Attachments"])},
    "virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Exhibition"])},
    "physical-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Exhibition"])},
    "you-can-edit-delete-exhibition-as-organiser-before ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can Edit / Delete Your Exhibition As Organiser Before "])},
    "days-of-the-date-of-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days Of The Date Of Exhibition "])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
    "accepted ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted "])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "attendees-subscribed ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendees Subscribed "])},
    "above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Above"])},
    "buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyers"])},
    "nature-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature Exhibition"])},
    "physical-exhibition-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Exhibition Address"])},
    "the-link-is-sent-to-exhibitors-and-attendees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: The link Is Sent To Exhibitors And Attendees By Email At "])},
    "visible-in-the-exhibition-on-day-of-the-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On The Exhibition Day And Will Be Visible In The Exhibition On The Day Of The Exhibition"])},
    "cancel-subscription ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Subscription "])},
    "want-to-cancel-subscription-for-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Cancel Your Subscription For The Exhibition "])},
    "reason-of-cancelling-subscription-for-the-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What Is The Reason Of Cancelling Your Subscription For The Exhibition?"])},
    "due-to-some-reasons-exhibition-postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due To Some Reasons Exhibition Is Postponed"])},
    "cancel-exhibition-subscription-request-sent-to-super-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Request To Cancel Your Subscription For The Exhibition Is Sent To The Super Admin\nYou Will Be Informed After Accepting The Request"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "pending-requests ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Requests "])},
    "accepted-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted Requested"])},
    "artist-art-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist/Art Type"])},
    "accepted-requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted Requests"])},
    "reject-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Request"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Request"])},
    "purchase-pending ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Pending "])},
    "the-crowd-funding-is-accepted ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Crowd Funding Is Accepted "])},
    "sale ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale "])},
    "art-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art In Progress"])},
    "art-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art Completed"])},
    "payment-done-from ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Done From"])},
    "delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
    "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Request"])},
    "paid-to-seller ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To Seller "])},
    "artist-review-on-buyers-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist Review On Buyers Comment"])},
    "add-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Credit"])},
    "total-credit ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Credit "])},
    "delete-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Credit"])},
    "want-to-delete-the-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Credit?"])},
    "view-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Credit"])},
    "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense"])},
    "view-expence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Expense"])},
    "add-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD Expense"])},
    "expense-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense Amount"])},
    "expense-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expense Type"])},
    "paid-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid To"])},
    "payment-due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due Date"])},
    "paid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Date"])},
    "payment-status ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status "])},
    "waiting-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Payment"])},
    "profit-loss ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit / Loss:"])},
    "debit ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit:"])},
    "bank-transaction ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transaction "])},
    "add-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Transaction"])},
    "discrepancy-between-system-audit-and-bank-audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“There Is A Discrepancy Between System Generated Audit And Bank Balance Audit.”"])},
    "bank-balance ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Balance "])},
    "bank-transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transaction Date"])},
    "want-to-delete-bank-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You want to delete the Bank Transaction?"])},
    "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Title"])},
    "mail-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Template"])},
    "mail-contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Contents"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "invite-referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Referrals"])},
    "referral-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Name"])},
    "referral-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Email Id"])},
    "referred-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred By"])},
    "referred-dates\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referred Dates\n"])},
    "subscribed-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Date"])},
    "referral-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Link"])},
    "generates-an-email-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generates An Email List By Entering Multiple Email Addresses Separated By A Comma In A Single Input Field"])},
    "invite-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Message"])},
    "send-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invite"])},
    "delete-refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Referral"])},
    "want-to-delete-the-referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Referral"])},
    "errors-log ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors Log "])},
    "error-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Code"])},
    "error-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Title"])},
    "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "view-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Error"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTEXT"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "export ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export "])},
    "violation-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violation Report"])},
    "customer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Name"])},
    "module-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module Membership"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "tabular-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabular View"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "search-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Categories"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "balanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanced"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "from-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
    "to-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
    "send-reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reply"])},
    "add-email-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Email Templates"])},
    "date-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Updated"])},
    "created-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
    "used-in-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used In Function"])},
    "html-file ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML File "])},
    "page-name-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Name/URL"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
    "about-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us "])},
    "edit-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Translation"])},
    "view-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Translation"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "subscription-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Fees"])},
    "agreements\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreements\n"])},
    "slack-function ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Function "])},
    "slack-integration ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Integration "])},
    "auto-email-dispatch\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Email Dispatch\n"])},
    "base-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base URL"])},
    "site-access-reminder-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Access Reminder Time"])},
    "referral-rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Rewards"])},
    "japan-consumption-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPAN Consumption Tax"])},
    "japan-tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAPAN Tax Registration Number"])},
    "image-upload-max-size-free-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Upload Max Size (Free User)"])},
    "image-upload-max-size-paid-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Upload Max Size (Paid User)"])},
    "applicable-currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable Currency"])},
    "feedback-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback / Review"])},
    "unpublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish"])},
    "weeks ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks "])},
    "free-disk-quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Disk Quota"])},
    "own-file-server-disk-quota-cost-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own File Server Disk Quota Cost / Year\n"])},
    "sekai-ichi-disk-quota-rental-cost-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Disk Quota Rental Cost / Year"])},
    "slack-notification-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Notification Fees"])},
    "advance-deposit-cost-for-rental-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advance Deposit Cost For Rental Arts"])},
    "service-charge-on-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge On Orders"])},
    "service-charge-on-exhibition-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge On Exhibition User"])},
    "service-charge-for-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Charge For Crowdfunding"])},
    "anniversary-event-discount-in-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anniversary Event Discount In Orders"])},
    "limit-to-delivery-insurance-charge-for-domestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit To Delivery + Insurance Charge For Domestic"])},
    "limit-to-delivery-insurance-charge-for-international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit To Delivery+Insurance Charge For International"])},
    "add-new-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Payment Method"])},
    "financial-year ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Year "])},
    "start-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START MONTH"])},
    "end-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["END MONTH"])},
    "you-can-choose-start-and-end-of-financial-year ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Can Choose The Start & End Of Your Financial Year."])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "stripe-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe key"])},
    "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
    "sekai-engineering-it-solutions-private-limited  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Engineering & IT Solutions Private limited  "])},
    "make-payment-to-paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Payment To Paypal ID"])},
    "stripe-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe Secret"])},
    "local-file-server-hosting-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local File Server (Hosting Server)"])},
    "aws-s3-bucket-file-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS S3 Bucket File Server"])},
    "connection-tested-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection Tested Successfully."])},
    "check-connection-details-and-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could Not Connect To Your S3 Bucket, Please Check The Connection Details And Try Again"])},
    "international-delivery ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Delivery "])},
    "add-new-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Agreement"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "contents ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents "])},
    "delete-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Agreement"])},
    "want-to-delete-contract-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure, You Want To Delete The Contract Agreement"])},
    "agreement-is-in-use-it-can-not-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreement Is In Use, It Can Not Be Deleted."])},
    "add-new-slack-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Slack Function"])},
    "function-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function Name"])},
    "applicable-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable To"])},
    "required-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Fields"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenant"])},
    "view-slack-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Slack Function"])},
    "mobile-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile/ Contact"])},
    "add-new-slack-webhook-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Slack Webhook URL"])},
    "slack-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Channel"])},
    "add-new-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Module"])},
    "landing-page ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landing Page "])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
    "users-geography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users/Geography"])},
    "auto-email-dispatch\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Email Dispatch\n"])},
    "mail-mailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_MAILER"])},
    "mail-host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_HOST"])},
    "mail-port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_PORT"])},
    "mail-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_USERNAME"])},
    "mail-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_PASSWORD"])},
    "mail-encryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIL_ENCRYPTION"])},
    "file-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Server"])}
  }
}