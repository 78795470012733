<template>
	<div>
		<div class="flex items-center pb-1">
           <div class="block lg:hidden w-40 h-[60px] h-12 flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center">
                <img src="/images/logo.webp" class="w-24 object-contain">
            </div>             
            <div class="shadow-logoShadow rounded-bl-[40px] w-full ml-3 lg:ml-6 h-[50px] lg:h-[65px] flex justify-end">
                <div class="flex px-2 lg:px-4 2xl:px-8 items-center gap-2 md:gap-1 xl:gap-6 uppercase font-semibold text-[10px] lg:text-xs xl:text-sm 2xl:text-base tracking-wider w-full flex justify-end">
                    <a href="/" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >
                    <!-- Home -->{{$t('home')}}
                    </a>
                    <a href="javascript:void(0)" @click="redirectBtn('/gallery/art-gallery')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Art Gallery -->{{$t('art-gallery')}}</a>
                    <a href="javascript:void(0)" @click="redirectBtn('/gallery/photo-gallery')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Photo Gallery -->{{$t('photo-gallery')}}</a>
                    <a href="javascript:void(0)" @click="redirectBtn('/exhibition')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >
                    <!-- Exhibit -->{{$t('exhibit')}}
                    </a>
                    <a href="javascript:void(0)" @click="redirectBtn('/exhibition')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >
                    <!-- Art/Photo Exhibition -->{{$t('art-photo-exhibition')}}
                    </a>
                    <div class="" >
                        <div class="flex items-center rounded-full cursor-pointer" @click="dropUser = !dropUser">
                            <img :src="profile_image" class="w-6 h-6 md:w-8 md:h-8 border border-white hover:border-bgBlue rounded-full object-cover">
                        </div>
                        <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <div v-if="dropUser" class="relative">
                            <div class="absolute top-1.5 -left-2.5 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                <router-link to="artist-dashboard" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Dashboad -->{{$t('dashboard')}}
                                </router-link>
                                <router-link to="artist-profile" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Profile -->{{$t('profile')}}
                                </router-link>
                                <router-link to="/" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Sign Out -->{{$t('sign-out')}}
                                </router-link>
                            </div>
                        </div>
                    </div> 
                    <button @click="dropNotification = !dropNotification">
                        {{notificationCount ? notificationCount : ''}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 96 960 960"><path d="M109.5 883V762h90V518.5q0-94 54.5-170t145-98V229q0-33.583 23.708-57.042Q446.417 148.5 480 148.5t57.292 23.458Q561 195.417 561 229v21.5q91.5 22 145.5 97.5t54 170.5V762h91v121h-742ZM481 1003q-37.45 0-63.725-25.981Q391 951.037 391 914h179q0 37.5-25.981 63.25Q518.037 1003 481 1003Z"/></svg>
                    </button>
                    <button v-if="dropNotification" @click="dropNotification = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                    <div v-if="dropNotification" class="relative">
                        <div v-if="notifications.length" class="absolute top-4 -left-14 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                            <div v-for="(item,i) in notifications" :key="i">
                                <span @click="markAsSeen(item)" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex cursor-pointer">{{item.type+' : '+item.message}}</span>
                            </div>
                        </div>
                        <div v-else class="absolute top-4 -left-14 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                            <label>You don't have any notification</label>
                        </div>
                    </div>
                    <div class="hover:bg-opacity-50 hover:bg-bgBlue md:px-2 py-1 md:py-1.5 rounded border border-gray-500 cursor-pointer shadow-innerShadow" >
                        <div class="flex items-center gap-2" @click="dropLang = !dropLang">
                            <svg class="w-2.5 lg:w-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                            </svg>
                            <span>{{ langName }}</span>
                            <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="black"/></svg>
                        </div>
                        <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <div v-if="dropLang" class="relative ">
                            <ul class="absolute top-1.5 -left-2.5 bg-white backdrop-filter border backdrop-blur-md bg-opacity-30 rounded text-xs w-28 z-30">
                                <li :class="{ current : lang === langName }" class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md" v-for="lang in langList" @click="selectLang(lang.name); dropLang = !dropLang">{{ lang.name }}
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div>
                        <router-link :to="{name:'cart'}" class="w-[40px] md:w-[50px] h-[40px] md:h-[50px] flex items-center justify-center">
                            <svg class="fill-current text-black w-[20px] lg:w-[32px] h-[25px] " fill="none" viewBox="0 0 32 25">
                                <path fill="" d="M31.1024 4.56337c-.0931-.12102-.2128-.21909-.3497-.28669-.1369-.06761-.2876-.10295-.4403-.10331H7.21243l-.45-1.43c-.04943-.15355-.13527-.29285-.2502-.40603-.11492-.11319-.25552-.19689-.4098-.24397l-4.1-1.260004C1.87636.794626 1.7439.781096 1.6126.79355c-.1313.012453-.25886.050646-.3754.112397-.23535.124713-.411531.337813-.48977.592423-.078239.2546-.052131.52986.072581.76522.124711.23536.337809.41154.592419.48978l3.59 1.1 4.58 14.47003-1.63 1.34-.13.13c-.4031.4667-.63148 1.0591-.64608 1.6756-.01459.6166.18551 1.2191.56608 1.7044.27256.3314.61891.5945 1.01134.7681.39243.1737.82005.2531 1.24863.2319h16.69c.2652 0 .5196-.1054.7071-.2929.1876-.1876.2929-.4419.2929-.7071 0-.2653-.1053-.5196-.2929-.7071-.1875-.1876-.4419-.2929-.7071-.2929H9.84243c-.11515-.004-.22735-.0375-.32574-.0975-.0984-.0599-.17967-.1442-.23597-.2448-.05629-.1005-.08571-.2139-.08541-.3291.00029-.1152.0303-.2284.08712-.3286l2.40997-2h15.43c.2285.0066.4523-.0652.6342-.2034.182-.1383.3111-.3348.3658-.5566l3.2-13.00003c.0304-.14909.0264-.30314-.0117-.45044s-.1094-.28396-.2083-.39956Z"/>
                            </svg>
                        </router-link>
                    </div>
                </div>

                <div class="block md:hidden flex items-center mr-2">
                    <button type="button" @click="mobileHeader = !mobileHeader">
                        <svg class="text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none ">
                        <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                            <div class="shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                <div class="flex flex-col px-4 py-2 space-y-3">
                                    <a href="/" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Home -->{{$t('home')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/art-gallery')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Art Gallery -->{{$t('art-gallery')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/photo-gallery')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Photo Gallery -->{{$t('photo-gallery')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/exhibition')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Exhibit -->{{$t('exhibit')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/exhibition')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Art/Photo Exhibition -->{{$t('art-photo-exhibition')}}</a>
                                </div>

                                <div class="absolute inset-y-2 right-2">
                                    <button type="button" @click="mobileHeader = !mobileHeader">
                                        <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div v-if="isConfirm">
        <Confirmation 
            :title="'Confirmation to redirect'"
			:message="'You are about to be redirected to the buyer-side application. Do you want to continue ?'"
			@closeModelBox = "closeModelBox"
			@deleteRecord = "redirectToBuyer" 
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Confirmation from "@/components/DeleteRecordModelBox.vue";
export default  ({
    data: function() {
        return {
            langName: 'Eng',
            langList: [
                {name:'Eng'},
                {name:'Jap'},
            ],
            dropLang: false,
            dropUser: false,
            mobileHeader: false,   
            profile_image: '/images/user.webp',     
            url:'',
            isConfirm:false,
            buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
            notifications:[],
            notificationCount:0,
            dropNotification:false
        }
    },
    components: {
        Confirmation
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    methods: {
        ...mapMutations({
        }),

        selectLang(option) {
            this.langName = option;
        },
        async getProfileDetails(){
            let emailid = localStorage.getItem('email');
            const payload = {
                email : emailid
            }
            try{
                const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                if(res.profile_image){
                        this.profile_image = res.profile_image;
                    }
                
            } catch(error){
                console.log("Error in response", error);
            }finally{
               
            }
        },
        closeModelBox(){
            this.isConfirm = false;
            this.url = '';
        },
        redirectBtn(query){
            this.isConfirm = true;
            this.url = query;
        },
        redirectToBuyer(){
            window.open(this.buyerBaseUrl+this.url);
            this.closeModelBox();
        },
        async getNotifications(){
            try{
                const res = await this.$store.dispatch("getBellNotification");
                if(res.success){
                    this.notifications = res.data.data;
                    this.notificationCount = res.count;
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        },
        async markAsSeen(data){
            try{
                const res = await this.$store.dispatch("markSeenBellNotification",{payload:{id:data.id}});
                if(res.success){
                    window.open(process.env.VUE_APP_LOCAL_API+data.link,'frameName');
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        }
    },

    mounted() {
        this.getNotifications();
        this.getProfileDetails();
    },
    
});
</script>